import React from 'react';
import { Avatar } from '@material-ui/core';
import './SidebarRow.css';

function SidebarRow({src, Icon, title}) {
  return (
    <div className="sidebarRow__root">
      {src && <Avatar src={src} />}
      {Icon && <Icon />}
      <h4> {title} </h4>
    </div>
  )
}

export default SidebarRow
